<template>
  <pt-bill-delay class="pt-bill-delay__page"></pt-bill-delay>
</template>

<script>
import PtBillDelay from 'cps/trust-order/pt-bill-delay/withData'
export default {
  name: 'pt-bill-delay__view',
  components: {
    'pt-bill-delay': PtBillDelay
  }
}
</script>
