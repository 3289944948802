<template>
  <div :class="$style['pt-bill-delay']">
    <validate-form tag="div" ref="form">
      <pt-form-item>
        <div :class="$style['form-item-box']">
          <span>开始日期：</span>
          <p>
            {{$route.query.start_date}}
          </p>
        </div>
      </pt-form-item>
      <pt-form-item>
        <div :class="$style['form-item-box']">
          <span>目标日期：</span>
          <p>
            {{$route.query.delivery_date}}
          </p>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="延期日期"
        rules="required"
        ref="username"
      >
        <div :class="[$style['form-item-box'],$style['required']]">
          <span>延期日期：</span>
          <pt-date-picker  v-model="formValue.delayDate" title="延期日期"></pt-date-picker>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="延期原因"
        rules="required"
        ref="username"
      >
        <div :class="[$style['form-item-box'],$style['required']]">
          <span>延期原因：</span>
          <cube-select
            v-model="formValue.delayReason"
            :options="options">
          </cube-select>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="备注"
      >
        <div :class="$style['form-item-box']">
          <span>备注：</span>
          <wt-input v-model="formValue.comment" placeholder="请填写备注">
          </wt-input>
        </div>
      </pt-form-item>
    </validate-form>
    <div :class="$style['btn-box']">
      <wt-button :class="$style['submit-btn']" @click="submit" primary>确认提交</wt-button>
    </div>
    
  </div>
</template>

<script>
import { formatDate } from '@/utils/formatUtils/date.js'
// 组件class前缀
const prefixCls = 'pt-bill-delay'

export default {
  name: 'pt-bill-delay',
  computed: {
    classes () {
      return `${prefixCls}`
    },
    startDate() {
      let startDate = new Date();
      return new Date(
        startDate.getFullYear() - 100,
        startDate.getMonth(),
        startDate.getDate(),
        0,
        0,
        0
      );
    },
  },
  data () {
    return {
      formValue: {},
      options:[
				'样品较多，正在进行中，需要延期',
				'缺失标准品或试剂',
				'仪器故障',
				'需要提供仪器配件、色谱柱等',
				'实验结果超标或不符合预期，需重新复测',
				'样品量不够，或样品本身不适用，需要重新寄样',
				'实验后发现方法不合适，需要客户重新提供方法',
				'未提供有效方法，实验需要时间摸索',
				'客户先确认电子数据结果，再进行后续实验或出原始记录',
				'实验员不能正常到岗，需要延期',
				'送至其他部门或外送，需要延期'
			],
      // authorizationCode:null
    };
  },
  methods:{
    submit () {
      this.$refs.form.validate("formValue").then((valid) => {
        if(!valid){
          return
        }
        this.$loading()
        let data = {
          ids:[this.$route.params.id],
          ...this.formValue
        }
        this.$store.dispatch('handleDelay', data).then(res => {
          this.$toast.success('操作成功')
          this.$router.go(-1)
        }).catch(err => {
          this.$toast.error(err || '操作失败')
        }).finally(()=>{
          this.$loading.hide()
        })
      });
      
    
    },
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-bill-delay{
  > div:first-child{
    background-color: #fff;
    padding:@page-padding 25px;
    >div{
      &:last-child{
        .form-item-box{
          border-bottom: none;
        }
      }
    }
  }
  .btn-box{
    padding: 20px @page-padding;
    :global{
      .cube-btn{
        width: 100%;
      }
    }
  }
}
.form-item-box{
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid @page-background-color;
  &.required{
    > span{
      position: relative;
      &::before{
        content: '*';
        position: absolute;
        color:red;
        left: -10px;
        top: 0;
        width: auto;
      }
    }
  }
  > span{
    white-space: nowrap;
    width: 80px;
    text-align: left;
  }
  > p{
    padding: 10px;
  }
  :global{
    .wt-input,
    .cube-select{
      flex: 1;
      text-align: left;
      &::after{
        border: none;
      }
    }
  }
}
</style>
