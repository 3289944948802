<template>
  <pt-bill-delay class="pt-bill-delay__withData"></pt-bill-delay>
</template>

<script>
import PtBillDelay from './index'
export default {
  name: 'pt-bill-delay__withData',
  components: {
    'pt-bill-delay': PtBillDelay
  }
}
</script>
